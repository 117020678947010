var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"menu"},[_c('p',{staticClass:"menu-label"},[_vm._v("General")]),_c('ul',{staticClass:"menu-list"},[_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'home',
        },attrs:{"to":"/"}},[_vm._v("Dashboard")])],1)]),_c('p',{staticClass:"menu-label"},[_vm._v("Report")]),_c('ul',{staticClass:"menu-list"},[_c('li',[_c('router-link',{class:{
          'is-active': _vm.currentRoutePath === '/report1',
        },attrs:{"to":"/report1"}},[_vm._v("1. Manufacturer's Product")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report2',
        },attrs:{"to":"/report2"}},[_vm._v("2. Category")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report3',
        },attrs:{"to":"/report3"}},[_vm._v("3. Actual vs Predicted Revenue for GPS")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report4',
        },attrs:{"to":"/report4"}},[_vm._v("4. Store Revenue by Year by State")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report5',
        },attrs:{"to":"/report5"}},[_vm._v("5. Air Conditioners on Groundhog Day")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report6',
        },attrs:{"to":"/report6"}},[_vm._v("6. State w. Highest Volume Per Category")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'report7',
        },attrs:{"to":"/report7"}},[_vm._v("7. Revenue by Population")])],1),_c('li',[_c('router-link',{class:{
          'is-active': _vm.currentRoutePath === '/report8',
        },attrs:{"to":"/report8"}},[_vm._v("8. Membership Trends")])],1)]),_c('p',{staticClass:"menu-label"},[_vm._v("Configuration")]),_c('ul',{staticClass:"menu-list"},[_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName && _vm.currentRouteName.toLowerCase() === 'holiday',
        },attrs:{"to":"/holiday"}},[_vm._v("Holiday")])],1),_c('li',[_c('router-link',{class:{
          'is-active':
            _vm.currentRouteName &&
            _vm.currentRouteName.toLowerCase() === 'population',
        },attrs:{"to":"/population"}},[_vm._v("City Population")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
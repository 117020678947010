<template>
  <footer class="footer">
    <div class="has-text-centered">
      <div>
        Created By Jason Chen
      </div>
      <a
        href="https://github.com/inspireSen/vue-reports"
        >Source Code</a
      >
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>